<template>
    <b-modal id="modal-color-picker" title="Cores" size="md" no-enforce-focus @hide="hide">
        <div class="cores">
          <div class="paleta d-flex justify-content-between">
            <div v-for="cor in cores1" :key="cor" :class="`box rounded-circle${corSelecionada == cor ? ' selecionado' : ''}`" :style="{ backgroundColor: cor }" @click="corSelecionada = cor"></div>
          </div>
          <div class="paleta d-flex justify-content-between">
            <div v-for="cor in cores2" :key="cor" :class="`box rounded-circle${corSelecionada == cor ? ' selecionado' : ''}`" :style="{ backgroundColor: cor }" @click="corSelecionada = cor"></div>
          </div>
        </div>
        <template #modal-footer="{ cancel }">
            <b-button variant="light" class="rounded-0" @click="cancel">Cancelar</b-button>
            <b-button variant="success" class="rounded-0" @click="salvar" :disabled="!corSelecionada">Confirmar</b-button>
        </template>
    </b-modal>
</template>
  

<script>
    export default {
        name: 'ModalColorPicker',
        data: function() {
            return {
                retornoCor: null,
                cores1: ["#1c26f6", "#688df6", "#127aa6", "#16c8e2", "#14ad6e", "#07f090", "#3c9601", "#74dc33", "#839017", "#cfe60e"],
                cores2: ["#8c6f15", "#e3b013", "#81370f", "#d95a15", "#a90202", "#f81010", "#9d116a", "#e80e98", "#690a90", "#a319dc"],
                corSelecionada: null
            }
        },
        methods: {
            selecionarCor() {
                this.$bvModal.show("modal-color-picker");
                return new Promise(resolve => this.retornoCor = resolve);
            },
            hide() {
                this.corSelecionada = null;
            },
            salvar(){
                this.retornoCor(this.corSelecionada);
                this.$bvModal.hide("modal-color-picker");
            }
        }
    };
</script>

<style>
.paleta {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap
}
.paleta h4 {
    margin: 0 0 0.5em;
    width: 100%;
}   
.paleta .box {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    cursor: pointer;
    opacity: 0.65;
    zoom: 1;
    transition: all ease-in-out .3s;
}
.paleta .box:hover {
    opacity: 1;
}

.paleta .box.selecionado {
    opacity: 1;
    transform: scale(1.4)
}
</style>