<template>
	<b-modal v-model="exibe"
			 :static="true"
			 title="Trocar atendente"
			 size="md"
			 hide-footer centered
			 >
		<div class="data-field">
			
			<b-container class="p-0">
				<b-row align-h="center">
					<b-col>
						<div class="w-100">
							<label class="w-100 m-0 h6 font-size-label-input-select">Selecione o Atendente</label>
							<select class="w-100 input-select" v-model="atendente_selecionado" >
								<option selected value="">Selecione ...</option>
								<option v-for="(opc, i) in usuarios" :key="i" :value="opc">
									{{opc.Nome}}
								</option>
							</select>
						</div>
					</b-col>
					<b-col cols="12" md="auto" class="pl-0 py-1">
						<div class="w-100">
							<b-button variant="success" style="margin-top:17px;" @click="trocarAtendente" >
								Salvar
							</b-button>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
		
	</b-modal>
</template>

<script>
	import axios from "axios";
	

	export default {
		name: "ModalSelecaoAtendente",
		data() {
			return {
				usuarios: [],
				exibe: false,
				atendente_selecionado: "",
				atendimentoId: null,
				resolver: null
			};
		},
        created() {
            
        },
		methods: {
            show(atendimentoId) {
				this.atendente_selecionado = "";
				this.atendimentoId = atendimentoId;
				this.exibe = true;
				this.buscaUsuarios();
				return new Promise(resolve => {
					this.resolver = resolve;
				});
            },
			hide() {
				this.exibe = false;
				this.resolver = null;
			},
			buscaUsuarios() {
				axios.get(`/api/usuario/lista-basica/`).then(response => {
					this.usuarios = response.data;
				})
                
			},
			trocarAtendente() {

				axios.put(`/api/atendimento/TrocarUsuarioAtendimento/` + this.atendente_selecionado.Id + '/' + this.atendimentoId).then(response => {
					this.usuarios = response.data;
                    this.resolver(this.atendente_selecionado.Nome);
					this.hide();
                }).catch(erro => {
                    console.log(erro);
                })
			}
		}
	}
</script>

<style scoped>
	

	

</style>